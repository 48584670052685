import ModalClose from '@mui/joy/ModalClose';
import {Stack} from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, {useState} from 'react';
import {useProducts} from '../../contexts';
import {ProgressBar} from '../../core-component/progress-bar/ProgressBar';
import {getItems} from '../../service/getItems';
import {setNewRows} from '../../service/setNewRows';
import {updateCurrentRow} from '../../service/updateCurrentRow';
import {itemsURL} from '../../service/urls';
import {IItemDTO} from '../../types';
import {MyFormik} from '../Modals/Formik';
import {ImageDownloader} from './imageDownlader/ImageDownolader';
import {MyGalery} from './imageGalery/MyGalery';
import {intialStateProductsInput} from './initialStateProductsInputs';
import {inputInfoProduct} from './inputInfoProducts';
import {stylesProductsBox} from './styles';
import {validationSchemaProducts} from './validationScemaProducts';

interface EditCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  isNew: boolean;
  id: string;
}

const EditProductModal: React.FC<EditCategoryModalProps> = ({
  isOpen,
  onClose,
  isNew,
  id,
}) => {
  const {products, setProducts, getProductsWithCategoryNames} = useProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const product: IItemDTO | undefined = products?.find((p) => p._id === id);

  let imageUrl: any = isNew ? [] : product?.images;
  const [imagesArr, setImagesArr] = useState<any[]>(imageUrl);

  const handleFormik = async (newValue: any) => {
    setIsLoading(true);
    if (!isNew) {
      try {
        await updateCurrentRow(itemsURL, {
          ...newValue,
          categories: undefined,
          categoryId: newValue.categoryId,
          images: imagesArr,
        });

        setProducts!(getProductsWithCategoryNames!(await getItems()));
        setTimeout(onClose, 1000);
        setSuccess(true);
      } catch (err) {
        console.error('bad request');
        setSuccess(false);
      }
    } else {
      try {
        await setNewRows(itemsURL, {
          ...newValue,
          categoryId: newValue.categoryId,
          images: imagesArr,
        });
        setProducts!(getProductsWithCategoryNames!(await getItems()));
        setTimeout(onClose, 1000);
        setSuccess(true);
      } catch (err) {
        console.error('bad request');
        setSuccess(false);
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={stylesProductsBox} className={'edit-prodcut-modal'}>
        <ProgressBar isLoading={isLoading} success={success} />
        {
          <div className={'modal-left-side'}>
            {imagesArr.length ? (
              <MyGalery
                disabled={isLoading}
                setImagesArr={setImagesArr}
                images={imagesArr}
              />
            ) : (
              <img
                style={{height: '273px', width: '235px'}}
                src="https://www.tenstickers-australia.com/build/images/web/services/upload.jpg"
                alt="oops"
              />
            )}
            <Stack direction="row" spacing={3}>
              <ImageDownloader
                disabled={isLoading}
                setImagesArr={setImagesArr}
              />
            </Stack>
          </div>
        }
        <MyFormik
          onClose={onClose}
          disabled={isLoading}
          initialValues={isNew ? intialStateProductsInput : product}
          inputState={inputInfoProduct}
          validationSchema={validationSchemaProducts}
          handleFormik={handleFormik}
          key={'edit-product-modal'}
          className={'edit-product-modal__formik'}
        />
        <ModalClose onClick={onClose} />
      </Box>
    </Modal>
  );
};

export {EditProductModal};
