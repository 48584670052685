import { itemsURL } from "./urls";
import { IItemDTO } from "@/types";
import api from "./axiosInstance";

export const getItems = async (): Promise<IItemDTO[]> => {
  try {
    const response = await api.get<IItemDTO[]>(itemsURL);

    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw Error("bad request");
  }
};
