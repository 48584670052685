import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import {GridActionsCellItem} from '@mui/x-data-grid';
const ariaLabel = {'aria-label': 'description'};

export const ColumnsProduct = (
  handleDeleteClick: (id: string) => void,
  handleEditClick: (id: string) => void
) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'super-app-theme--cell1',
      flex: 1,
      minWidth: 0,
      editable: true,
      type: 'text',
    },
    {
      field: 'description',
      headerName: 'Описание',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 0,
      editable: true,
    },
    {
      field: 'image',
      headerName: 'Изображения',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 51,
      editable: true,
      renderCell: (params: any) => {
        return (
          <AvatarGroup total={params.row.images.length}>
            {params?.row?.images.map((imageUrl: any, index: number) => (
              <Avatar
                key={index}
                alt={`Avatar ${index}`}
                src={
                  params.row.images && params.row.images.length > 0
                    ? imageUrl?.preview || imageUrl
                    : 'https://www.tenstickers-australia.com/build/images/web/services/upload.jpg'
                }
              />
            ))}
          </AvatarGroup>
        );
      },
    },
    {
      field: 'price',
      headerName: 'Цена',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 0,
      editable: true,
    },
    {
      field: 'quantity',
      headerName: 'Количество',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 0,
      editable: true,
    },
    {
      field: 'categories',
      headerName: ' Категории',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      minWidth: 0,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['0', '10', '20', '30'],
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      cellClassName: 'actions',
      getActions: ({id}: any) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
};
